import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConfigTypeEnum } from '../../../../../../shared/enums/user-config-type.enum';
import { WarningLevelFanucEnum } from '../../../../../../shared/enums/warning-level-fanuc.enum';
import { DictionaryInterface } from '../../../../../../shared/interfaces/dictionary.interface';
import { UserConfigInterface } from '../../../../../../shared/interfaces/user-config.interface';
import { ApiResourceLogsFanucService } from '../../../../../../shared/modules/api/services/api-resource-logs/api-resource-logs-fanuc/api-resource-logs-fanuc.service';
import { ApiResourceUserConfigService, } from '../../../../../../shared/modules/api/services/api-resource-user-config/api-resource-user-config.service';
import { AbstractFiltersService } from '../../../abstract-filters-service.service';
import { LogsFanucCategoryInterface } from '../../../interfaces/logs-category.interface';
import { LogsFanucCategoriesService } from '../../services/logs-fanuc-categories/logs-fanuc-categories.service';

@Injectable({
  providedIn: 'root',
})
export class LogsFanucFiltersService extends AbstractFiltersService<string> {
  protected readonly logsCategoriesService: LogsFanucCategoriesService;

  constructor(
    protected apiResourceLogsService: ApiResourceLogsFanucService,
    apiResourceUserConfigService: ApiResourceUserConfigService,
    logsCategoriesService: LogsFanucCategoriesService,
  ) {
    super(
      UserConfigTypeEnum.LOGS_FANUC_PAGE_CATEGORIES,
      apiResourceUserConfigService,
      logsCategoriesService,
    );
  }

  getFiltersCategories$(
    deviceId: string,
    dateRange: unknown,
  ): Observable<LogsFanucCategoryInterface[]> {
    return this.apiResourceLogsService.getLogsCategories$(deviceId, dateRange);
  }

  areCategoriesTheSame(dict1: DictionaryInterface<boolean>, dict2: DictionaryInterface<boolean>): boolean {
    for (const category of this.logsCategoriesService.getAllCategories()) {
      if (dict1[category] !== dict2[category]) {
        return false;
      }
    }
    return true;
  }

  protected cast(val: string): string {
    return val;
  }

  protected getDefaultConfig(): Omit<UserConfigInterface<string[]>, 'id'> {
    return {
      config_type: this.configType,
      label: this.configLabel,
      config: [WarningLevelFanucEnum.FAULT, WarningLevelFanucEnum.ERROR, WarningLevelFanucEnum.WARNING],
    };
  }
}
