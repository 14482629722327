import { Injectable } from '@angular/core';
import { LogsCategoriesServiceInterface } from '../../../interfaces/logs-categories-service.interface';

@Injectable({
  providedIn: 'root',
})
export class LogsFanucCategoriesService implements LogsCategoriesServiceInterface<string> {
  /**
   * Categories definitions:
   * https://kogena.atlassian.net/wiki/spaces/~620998572/pages/56786947/Message+category
   *
   * API schema uses category code instead of UUID therefore the mapper.
   */
  getCategories(): string[] {
    return ['FAULT', 'WARNING', 'ERROR'];
  }


  getAllCategories(): string[] {
    return this.getCategories();
  }
}
