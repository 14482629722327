import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogsFanucCategoryInterface } from '../../../../../../modules/diagnostic/logs/interfaces/logs-category.interface';
import { LogsFanucInterface } from '../../../../../../modules/diagnostic/logs/interfaces/logs.interface';
import { DictionaryInterface } from '../../../../../interfaces/dictionary.interface';
import { apiUrlLogsFanuc } from '../../../constants/api-url.constant';
import { ApiParamsService } from '../../api-params.service';
import { ApiResourceLogsFanucServiceInterface } from '../interfaces/api-resource-logs.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiResourceLogsFanucService implements ApiResourceLogsFanucServiceInterface {
  constructor(
    private httpClientService: HttpClient,
    private apiParamsService: ApiParamsService,
  ) {}

  getLogs$(
    deviceId: string,
    paramsDict: DictionaryInterface<string | number | boolean>,
    filters: {
      programsType: string;
      programNames: string[];
    },
  ): Observable<LogsFanucInterface> {
    let params = new HttpParams().appendAll(paramsDict);
    params = this.apiParamsService.removeEmptyValues(params);
    return this.httpClientService.get<LogsFanucInterface>(apiUrlLogsFanuc(deviceId), {
      params,
    }).pipe(
      catchError(() => {
        return of({
          all_logs: 0,
          all_pages: 0,
          next: '',
          previous: '',
          page: 1,
          limit: 0,
          results: [],
          message_categories: [],
        });
      }),
    );
  }

  getLogsCategories$(_deviceId: string, _dateRange: unknown): Observable<LogsFanucCategoryInterface[]> {
    // let params = new HttpParams();
    // params = this.apiParamsService.applyDateRange(params, dateRange);
    //
    // return this.httpClientService.get<LogsUrCategoryInterface[]>(apiUrlLogsUrCategories(deviceId), {
    //   params,
    // });
    return of([{
      id: '',
      name: $localize`Fault`,
      count: 0,
      code: 'FAULT',
    }, {
      id: '',
      name: $localize`Error`,
      count: 0,
      code: 'ERROR',
    }, {
      id: '',
      name: $localize`Warning`,
      count: 0,
      code: 'WARNING',
    }]);
  }
}
