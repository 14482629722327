import { Pipe, PipeTransform } from '@angular/core';
import { COLOR_PRIMARY, ColorErrorEnum, ColorWarningEnum } from '../../../../../../shared/enums/color.enum';
import { WarningLevelFanucEnum } from '../../../../../../shared/enums/warning-level-fanuc.enum';

@Pipe({
  name: 'logsFanucCategoryPipe',
  standalone: true,
})
export class LogsFanucCategoryPipe implements PipeTransform {
  private readonly colorDefault: string = COLOR_PRIMARY;

  transform(categoryCode: string): {
    icon: string;
    color: string;
    isDevCategory: boolean;
    name: string;
  } {
    switch(categoryCode) {
      case WarningLevelFanucEnum.WARNING:
        return {
          color: ColorWarningEnum.HUE3,
          icon: 'report_problem',
          isDevCategory: false,
          name: $localize`Warning`,
        };
      case WarningLevelFanucEnum.ERROR:
        return {
          color: ColorErrorEnum.HUE3,
          icon: 'cancel',
          isDevCategory: false,
          name: $localize`Error`,
        };
      case WarningLevelFanucEnum.FAULT:
        return {
          color: ColorErrorEnum.HUE1,
          icon: 'report',
          isDevCategory: false,
          name: $localize`Fault`,
        };
      default:
        return {
          icon: 'question_mark',
          color: this.colorDefault,
          isDevCategory: false,
          name: $localize`string`,
        };
    }
  }
}
