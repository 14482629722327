import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BehaviorSubject, combineLatest, forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FiltersStoreService } from 'src/app/modules/diagnostic/filters/store/filters-store.service';
import { HintComponent } from '../../../../../../shared/components/hint/hint.component';
import { Subjectize } from '../../../../../../shared/decorators/subjectize.decorator';
import { RobotDateRangeInterface } from '../../../../../../shared/interfaces/date-range.interface';
import { DeviceInterface } from '../../../../../../shared/interfaces/device.interface';
import { DictionaryInterface } from '../../../../../../shared/interfaces/dictionary.interface';
import { UserConfigInterface } from '../../../../../../shared/interfaces/user-config.interface';
import { FormCheckboxComponent } from '../../../../../../shared/modules/form/components/form-checkbox/form-checkbox.component';
import { FormSearchComponent } from '../../../../../../shared/modules/form/components/form-search/form-search.component';
import { TimeFormatPipe } from '../../../../../../shared/pipes/time-format/time-format.pipe';
import { LogsFanucCategoryInterface } from '../../../interfaces/logs-category.interface';
import { LogFanucMessageCategoryInterface } from '../../../interfaces/logs.interface';
import { LogsTooltipIconComponent } from "../../../logs-tooltip-icon/logs-tooltip-icon.component";
import { LogsFanucCategoryPipe } from '../../pipes/logs-fanuc-category/logs-fanuc-category.pipe';
import { LogsFanucFiltersService } from './logs-fanuc-filters.service';
import { isEqual } from "lodash-es";

@Component({
  selector: 'app-logs-fanuc-filters',
  templateUrl: './logs-fanuc-filters.component.html',
  styleUrls: ['./logs-fanuc-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LogsFanucFiltersService,
  ],
  standalone: true,
  imports: [
    CommonModule,
    HintComponent,
    MatIcon,
    RouterLink,
    NgScrollbarModule,
    FormCheckboxComponent,
    FormsModule,
    ReactiveFormsModule,
    FormSearchComponent,
    TimeFormatPipe,
    LogsFanucCategoryPipe,
    LogsTooltipIconComponent,
  ],
})
export class LogsFanucFiltersComponent implements OnInit, OnChanges {
  @Input() device: DeviceInterface;
  @Input() dateRange: RobotDateRangeInterface;
  @Input() messageCategoriesCounts: LogFanucMessageCategoryInterface[];
  @Subjectize('dateRange')
    dateRange$: BehaviorSubject<RobotDateRangeInterface | null> = new BehaviorSubject<RobotDateRangeInterface | null>(null);

  categoriesFormGroup: FormGroup = new FormGroup({});
  categories: LogsFanucCategoryInterface[] = [];
  Object = Object;
  private config: string[];

  searchFormControl: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
  });

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();
  private readonly saveDelay = 3000;

  constructor(
    private logsFanucFiltersService: LogsFanucFiltersService,
    private changeDetectorRef: ChangeDetectorRef,
    private state: FiltersStoreService,
  ) {}

  ngOnInit(): void {
    this.initCategories();
    this.initForm();
    this.initSearch();
  }

  ngOnChanges() {
    this.updateFiltersCounts();
  }

  private initCategories(): void {
    combineLatest([
      this.logsFanucFiltersService.getFiltersCategories$(this.device.id, this.dateRange),
      this.logsFanucFiltersService.getConfig$(),
    ]).pipe(
      tap(([categories, config]: [LogsFanucCategoryInterface[], UserConfigInterface<string[]>]) => {
        this.config = config.config && config.config.length > 0 ? config.config : categories.map(c => c.code);
        this.updateForm(categories, true);
        this.state.updateCategories(config.config as string[]);
      }),
      switchMap(() => this.dateRange$),
      switchMap((dateRange) => this.logsFanucFiltersService.getFiltersCategories$(
        this.device.id,
        dateRange!,
      )),
      tap((categories: LogsFanucCategoryInterface[]) => {
        this.updateForm(categories, false);
      }),
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  private updateFiltersCounts(): void {
    if(this.categories && this.messageCategoriesCounts){
      this.categories = this.categories.map((category) => {
        const matchingSecondObj = this.messageCategoriesCounts.find(
          (messageCategoryCount) => messageCategoryCount.code === category.code,
        );
        if (matchingSecondObj) {
          return { ...category, count: matchingSecondObj.count };
        }
        return category;
      });
    }
  }

  private updateForm(categories: LogsFanucCategoryInterface[], setValues: boolean): void {
    this.categories = categories;

    const config = this.config;
    categories.forEach((category: LogsFanucCategoryInterface) => {
      if (category.code in this.categoriesFormGroup.controls) {
        if (setValues) {
          this.categoriesFormGroup.controls[category.code].setValue(
            config.includes(category.code),
          );
        }
      } else {
        this.categoriesFormGroup.addControl(
          category.code,
          new FormControl(config.includes(category.code)),
          { emitEvent: false },
        );
      }
    });
    for (const key in this.categoriesFormGroup.controls) {
      if (!categories.some(category => category.code === key)) {
        this.categoriesFormGroup.removeControl(key);
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  initForm(): void {
    // Watch on categories' changes in order to send new data to store.
    this.categoriesFormGroup.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => this.logsFanucFiltersService.areCategoriesTheSame(prev, curr)),
      tap((categoriesFormGroupValue: DictionaryInterface<boolean>) => {
        if (!this.logsFanucFiltersService.isAnyCategoryChecked(categoriesFormGroupValue)) {
          this.state.resetCategories();
        } else {
          this.state.updateCategories(this.getCategoriesState(categoriesFormGroupValue));
        }

        this.changeDetectorRef.detectChanges();
      }),
      takeUntil(this.destroyed$),
    ).subscribe();

    this.categoriesFormGroup.valueChanges.pipe(
      debounceTime(this.saveDelay),
      switchMap((categories: DictionaryInterface<boolean>) => this.logsFanucFiltersService.saveConfig$(categories)),
    ).subscribe();
  }

  private initSearch(): void {
    this.searchFormControl.valueChanges.pipe(
      tap((searchPhrase: string) => {
        this.state.updateSearch(searchPhrase);
      }),
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  private getCategoriesState(categoriesFormGroupValue: DictionaryInterface<boolean>): string[] {
    return this.categories.reduce((payload: string[], cat: LogsFanucCategoryInterface) => {
      const isCategoryChecked: boolean = categoriesFormGroupValue[cat.code];

      if (isCategoryChecked) {
        payload.push(cat.code);
      }

      return payload;
    }, []);
  }
}
