<div class="logs-ur-filters-wrapper">
  <ng-container *ngIf="categories.length > 0 && categoriesFormGroup">
    <div class="kg-title logs-ur-filters__label">
      <div>Logs details</div>
      <app-hint>
        <div i18n>Select the message category from the following:</div>
        <app-logs-tooltip-icon i18n color="rgb(147, 10, 10)" icon="report">Fault</app-logs-tooltip-icon>
        <app-logs-tooltip-icon i18n color="rgb(220, 13, 14)" icon="cancel">Error</app-logs-tooltip-icon>
        <app-logs-tooltip-icon i18n color="rgb(222, 137, 13)" icon="report_problem">Warning</app-logs-tooltip-icon>
      </app-hint>
    </div>

    <div class="logs-ur-filters">
      <ng-scrollbar
        [autoHeightDisabled]="false"
        [track]="'horizontal'"
        [visibility]="'always'"
        class="logs-ur-filters-scrollbar"
      >
        <form class="logs-ur-filters__grid">
          @for (category of categories; track category.code; let idx = $index) {
              <div class="logs-ur-filters__element">
                <app-form-checkbox [formControl]="$any(categoriesFormGroup.controls[category.code])">
                  @let logsCategory = category.code | logsFanucCategoryPipe;
                  <mat-icon
                    [style.color]="logsCategory.color"
                    class="logs-ur-filters__icon"
                  >
                    {{ logsCategory.icon }}
                  </mat-icon>
                  <span
                    class="logs-ur-filters__counter"
                    [style.color]="logsCategory.color"
                  >
                    {{ category.count }}
                  </span>
                  <span>{{ category.name }}</span>
                </app-form-checkbox>
              </div>
          }
        </form>
      </ng-scrollbar>

      <div *ngIf="searchFormControl" class="logs-ur-filters-search">
        <app-form-search
          [formControl]="searchFormControl"
          class="logs-ur-filters-search__control"
        ></app-form-search>
      </div>
    </div>
  </ng-container>
</div>
